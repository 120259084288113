import React from 'react';

import Container from '@csv/styleguide/src/elements/container/container';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';

const AgendaPage = () => {
  return (
    <Layout>
      <SEO
        title="Evenementenagenda"
        description="Alle komende evenementen van CSV Handbal Castricum"
      />
      <PageHeaderComposition
        title="Agenda"
        byline="Alle komende en voorgaande clubevenementen van CSV Handbal."
      />
      <Container>
        <div style={{ overflowX: 'auto', minWidth: 500 }}>
          <iframe
            style={{ border: 0 }}
            src="https://calendar.google.com/calendar/embed?src=50tfvd0qkrft5f2o8d54jg4cak%40group.calendar.google.com&amp;ctz=Europe%2FAmsterdam"
            width="100%"
            height="800"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      </Container>
    </Layout>
  );
};

export default AgendaPage;
